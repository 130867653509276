import React from 'react';
import Grid from '@material-ui/core/Grid';
import appstore from 'assets/appstore.svg';
import googleplay from 'assets/googleplay.svg';
import facebookIcon from 'assets/facebook-icon.png';
import lineLogo from 'assets/line-logo.png';
import muzeLogo from 'assets/muze-logo.png';
import pumpkinHead from 'assets/pumpkin-head.png';
import logoMerchant from 'assets/logo-merchant.png';
import { Block, Image, Text } from 'components/atoms';
import MailOutline from '@material-ui/icons/MailOutline';

class FooterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Block background={'#f4f4f4'} position={'relative'}>
        <Image
          src={pumpkinHead}
          position={'absolute'}
          top={-60}
          left={'50%'}
          css={{ transform: 'translate(-50%, 0)' }}
        />
        <Block
          pt={30}
          pb={30}
          maxWidth={['initial', 700, 960, 1200]}
          mx={'auto'}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <Text
                fontFamily={'thai'}
                fontSize={22}
                fontWeight={900}
                color={'#767677'}
                textAlign={['center', 'center', 'left']}
                mt={[35, 25, 0]}>
                ติดต่อเราได้ที่
              </Text>
              <Text
                fontSize={16}
                textAlign={['center', 'center', 'left']}
                mt={20}>
                <a
                  href="mailto:pumpkinapp@muze.co.th"
                  style={{ color: '#767677', verticalAlign: 'top' }}>
                  <MailOutline style={{ marginRight: 10 }} />pumpkinapp@muze.co.th
                </a>
              </Text>
              <Block.Flex
                alignItems={'center'}
                mt={15}
                justifyContent={['center', 'center', 'flex-start']}>
                <a href={'https://line.me/R/ti/p/%40joj8029p'} target="_blank">
                  <Image src={lineLogo} width={50} mr={25} />
                </a>
                <a href={'https://facebook.com/pumpkinapp.co'} target="_blank">
                  <Image src={facebookIcon} width={50} />
                </a>
              </Block.Flex>
            </Grid>
            <Grid item xs={12} md={6}>
              <Text
                fontFamily={'thai'}
                fontSize={22}
                fontWeight={900}
                color={'#767677'}
                textAlign={['center', 'center', 'right']}
                mt={[30, 25, 0]}>
                ดาวน์โหลด
              </Text>
              <Block.Flex
                alignItems={'center'}
                justifyContent={['center', 'center', 'flex-end']}
                flexDirection={['column-reverse', 'column-reverse', 'row']}
                pt={15}>
                <Text color={'#767677'} mr={15}>
                  Pumpkin Merchant
                </Text>
                <Image src={logoMerchant} borderRadius={10} width={54} />
              </Block.Flex>
              <Block.Flex
                alignItems={'center'}
                mt={15}
                justifyContent={['center', 'center', 'flex-end']}>
                <a
                  href={
                    'itms://itunes.apple.com/th/app/pumpkin-merchant/id1392865058?mt=8'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}>
                  <Image src={appstore} width={135} mr={15} />
                </a>
                <a
                  href={'market://details?id=com.mi.pumpkin'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}>
                  <Image src={googleplay} width={135} />
                </a>
              </Block.Flex>
            </Grid>
          </Grid>
          <Block mt={[20, 25, 35]} pt={25} borderTop={'1px solid #eeeeee'}>
            <Grid container justify="space-between" alignItems="center">
              <Grid
                item
                xs={12}
                md={6}
                textAlign={['center', 'center', 'left']}>
                <Block.Flex
                  mt={[30, 20, 0]}
                  flexDirection={['column', 'column', 'row']}
                  alignItems={['center', 'center', 'flex-start']}
                  mb={[15, 15, 0]}>
                  <Text
                    fontFamily={'thai'}
                    fontSize={19}
                    fontWeight={'bold'}
                    color={'#767677'}
                    mr={[0, 0, 15]}>
                    Powered By
                  </Text>
                  <Image src={muzeLogo} width={30} />
                </Block.Flex>
              </Grid>
              <Grid item xs={12} md={6}>
                <Text
                  textAlign={['center', 'center', 'right']}
                  fontFamily={'thai'}
                  fontSize={19}
                  fontWeight={'bold'}
                  color={'#767677'}>
                  Copyright © 2018 Pumpkinapp. All rights reserved.
                </Text>
              </Grid>
            </Grid>
          </Block>
        </Block>
      </Block>
    );
  }
}

export default FooterSection;
