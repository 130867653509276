import React from 'react';
import { Text, Block } from 'components/atoms';

// REDUX
import { connect } from 'react-redux';
import { selectGetPlansStatus, selectPlans } from 'modules/package/selectors';
import { getPlans } from 'modules/package/actions';
import { bindActionCreators } from 'redux';

class PackageSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { plans } = this.props;
    if (!plans || !plans.length) {
      this.props.getPlans().then(result => console.log('result', result));
    }
  }

  render() {
    return (
      <Block
        pt={[48, 64, 80]}
        pb={[48, 64, 80]}
        maxWidth={['initial', 700, 960, 1200]}
        mx={'auto'}>
        <Text
          fontSize={30}
          textAlign={'center'}
          fontWeight={'bold'}
          color={'#f4a836'}
          letterSpacing={'1.5px'}
          mb={[25, 15, 0]}>
          PRICING
        </Text>
        {/* {plans.length > 0 && (
          <PricePlanTable
            plans={plans}
            showOnLandingPage
            autoSetActivePackage
            activePackage={plans.find(plan => plan.price === 599).id}
          />
        )} */}
      </Block>
    );
  }
}

export default connect(
  state => ({
    getPlansStatus: selectGetPlansStatus(state),
    plans: selectPlans(state)
  }),
  dispatch =>
    bindActionCreators(
      {
        getPlans
      },
      dispatch
    )
)(PackageSection);
