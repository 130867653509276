import React from 'react';
import withTheme from 'hocs/withTheme';
import { Block } from 'components/atoms';
import { HowItWorkSection } from 'components/LandingSection';
import {
  Header,
  MerchantBanner,
  MerchantCrmSection,
  MerchantPricePlan,
  Footer,
  MerchantContract,
  PumpkinFeature
} from 'components/molecules';

class Merchant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Block>
        <Header />
        <MerchantBanner />
        <Block>
          <Block
            textAlign={'center'}
            mx={'auto'}
            maxWidth={['initial', 700, 960, 1200]}
            position={'relative'}
            zIndex={10}>
            <Block
              mx={'auto'}
              width={[290, 500, 700]}
              height={[260, 300, 420]}
              px={[15, 0]}>
              <iframe
                title="How to use video"
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/4ELyEuqvaAE"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{
                  boxShadow: '0 8px 36px rgba(0,0,0,0.54)',
                  backgroundColor: 'rgba(255,203,122,1)'
                }}
              />
            </Block>
          </Block>
          <Block px={[20, 0]} id="How it works">
            <HowItWorkSection />
          </Block>
        </Block>

        <MerchantCrmSection />
        <PumpkinFeature state={'merchant'} />
        <MerchantPricePlan />
        <MerchantContract />
        <Footer />
      </Block>
    );
  }
}

export default withTheme()(Merchant);
