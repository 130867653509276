import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Text, Block, Image } from 'components/atoms';
import imageService from 'helpers/imageService';
import { FeatureBox } from 'components/molecules';

const features = [
  {
    name: 'feature1',
    featureTitle: 'สะสมแสตมป์ แลกของรางวัล',
    description:
      'สร้างความประทับใจให้กับลูกค้าของคุณมากขึ้นจากการมาใช้บริการเป็นประจำ ด้วยของรางวัลและ การสะสมแสตมป์'
  },
  {
    name: 'feature2',
    featureTitle: 'ส่งข่าวสารถึงกลุ่มลูกค้า',
    description:
      'ส่งข่าวสาร ประกาศจากร้านค้าไปถึงมือถือของลูกค้าของคุณ เพื่อให้ลูกค้าได้รับอัพเดทจากร้านค้าของคุณอยู่ตลอดเวลา'
  },
  {
    name: 'feature3',
    featureTitle: 'แคมเปญ และโปรโมชั่น',
    description:
      'ส่งของขวัญพิเศษ และโปรโมชั่นพิเศษ ให้กับลูกค้าคนพิเศษของคุณผ่านมือถือของลูกค้า ให้ลูกค้ารักร้านค้าของคุณมากขึ้น'
  },
  {
    name: 'feature4',
    featureTitle: 'โพลล์และแบบสอบถาม',
    description:
      'รับความคิดเห็นจากลูกค้าที่มาใช้บริการของคุณผ่านแบบสอบถามออนไลน์ และมอบรางวัลให้กับลูกค้าแทนคำขอบคุณ'
  },
  {
    name: 'feature5',
    featureTitle: 'ระบบสมาชิกและบัตรกำนัล',
    description:
      'ทำให้ลูกค้าของคุณเป็นคนพิเศษมากขึ้น และ กลับมาใช้บริการกับร้านค้าของคุณเป็นประจำ ด้วยสิทธิพิเศษที่ไม่เหมือนใคร'
  },
  {
    name: 'feature6',
    featureTitle: 'ข้อมูลฐานลูกค้า',
    description:
      'เก็บข้อมูลการใช้บริการจากลูกค้าของคุณ เพื่อนำข้อมูลไปสร้างแคมเปญ โปรโมชั่นได้ตรงกลุ่มลูกค้า และถูกเวลายิ่งขึ้น'
  }
];

class FeatureSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Block position={'relative'} textAlign={'center'}>
        <Image
          position={'absolute'}
          width={'100%'}
          top={0}
          left={0}
          right={0}
          src={imageService.getUrl({
            path: imageService.LANDING.featureTop
          })}
          css={{ transform: 'translateY(-90%)' }}
        />
        <Image
          position={'absolute'}
          width={'100%'}
          bottom={0}
          left={0}
          right={0}
          src={imageService.getUrl({
            path: imageService.LANDING.featureBottom
          })}
          css={{ transform: 'translateY(90%)' }}
        />
        <Block background={'#fff9ef'} pb={24} position={'relative'} zIndex={2}>
          <Block maxWidth={['initial', 700, 960, 1200]} mx={'auto'}>
            <Text
              fontSize={30}
              textAlign={'center'}
              fontWeight={'bold'}
              mb={[-20, 20]}
              color={'#f4a836'}
              letterSpacing={'1.5px'}>
              FEATURES
            </Text>
            <Grid container justify="center" alignItems="center">
              {features.map(feature => (
                <Grid item xs={12} md={4}>
                  <FeatureBox
                    imagePath={imageService.getUrl({
                      path: imageService.LANDING[feature.name]
                    })}
                    featureTitle={feature.featureTitle}
                    featureDescription={feature.description}
                  />
                </Grid>
              ))}
            </Grid>
          </Block>
        </Block>
      </Block>
    );
  }
}
export default FeatureSection;
