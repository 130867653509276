import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Block, Image, Text, Button } from 'components/atoms';
import { ContactForm } from 'components/forms/FormItems';
import imageService from 'helpers/imageService';
import cloudApi from 'api/cloudApi';
import Contactus from 'assets/Contactus-bg.png';

class ContactSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      finishSendingEmail: false,
      email: ''
    };
  }

  // sendEmail

  sendEmail = values => {
    this.setState({ loading: true, email: values.email });
    return cloudApi
      .addContractToGoogleSheet(values)
      .then(() => this.setState({ loading: false, finishSendingEmail: true }));
  };

  render() {
    const { emailData, loading, finishSendingEmail } = this.state;
    return (
      <Block>
        <Block
          pt={[20, 50, 80]}
          maxWidth={['initial', 700, 960, 1200]}
          mx={'auto'}
          id="Contact Us">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <Image
                maxWidth={[280, 360, 440]}
                src={imageService.getUrl({
                  path: imageService.LANDING.pumpkinTeam
                })}
                m={'auto'}
              />
            </Grid>
            <Grid item xs={12} md={6} p={[20, 0, 0]}>
              <Block position={'relative'}>
                <Image
                  src={imageService.getUrl({
                    path: imageService.LANDING.joinUs
                  })}
                  position={'absolute'}
                  maxWidth={[280, 360, 440]}
                  top={[-64, -88, -120]}
                  left={'50%'}
                  css={{
                    transform: 'translateX(-50%)'
                  }}
                />
                <Block
                  mx={[16, 0]}
                  borderRadius={20}
                  p={[20, 30, 40]}
                  pt={[50, 60, 70]}
                  css={{
                    '@media only screen and (min-width: 768px)': {
                      boxShadow: '0 0 15px 2px rgba(51, 51, 51, 0.15)'
                    }
                  }}>
                  {!finishSendingEmail && (
                    <ContactForm
                      onSubmit={this.sendEmail}
                      initialValues={emailData}
                      loading={loading}
                    />
                  )}
                  {finishSendingEmail && (
                    <Block textAlign={'center'}>
                      <Text
                        fontFamily={'thai'}
                        pt={20}
                        fontSize={25}
                        fontWeight={'bold'}>
                        ขอบคุณที่สนใจเข้าร่วมกับ Pumpkin{' '}
                      </Text>
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        rounded
                        size={'large'}
                        mt={20}
                        m={'auto'}>
                        สมัครใช้งานเลย
                      </Button>
                    </Block>
                  )}
                </Block>
              </Block>
            </Grid>
          </Grid>
        </Block>
        <Block backgroundImage={Contactus}>hello</Block>
      </Block>
    );
  }
}

export default ContactSection;
